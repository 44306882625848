import dayjs from "dayjs"
import router from '@/router'


const getDefaultState = () => { // state
    return {

        // PendingOrderSelectView中勾選的項目
        // [送料] - IN 
        item_selected_for_in: sessionStorage.getItem("material_pending_item_selected_for_in")
                        ? JSON.parse(sessionStorage.getItem("material_pending_item_selected_for_in"))
                        : [],
        // [退料] - OUT
        item_selected_for_out: sessionStorage.getItem("material_pending_item_selected_for_out")
                        ? JSON.parse(sessionStorage.getItem("material_pending_item_selected_for_out"))
                        : [],

        // 這個由待確認新增的運送訂單ID (用於顯示使用)
        pending_created_shipping_id: null,

        // 叫料管理的月份範圍(請搭配 session)
        material_management_range_date_idx: sessionStorage.getItem("material_management_range_date_idx")
                                    ? JSON.parse(sessionStorage.getItem("material_management_range_date_idx"))
                                    : [
                                        dayjs().startOf('year').format('YYYY-MM'), // 今年年初
                                        dayjs().format('YYYY-MM'), // 今年年底
                                    ]
     
        
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),

    mutations: { 
        setItemSelectedForIn(state, payload){ // 選單
            state.item_selected_for_in = payload
        },
        setItemSelectedForOut(state, payload){ // 選單
            state.item_selected_for_out = payload
        },

        setPendingCreatedShippingID(state, payload){
            state.pending_created_shipping_id = payload
        },

        // 設定叫料管理的月份範圍
        setMaterialManagementRangeDateIdx(state, payload){
            state.material_management_range_date_idx = payload

            // 記錄到 session
            sessionStorage.setItem('material_management_range_date_idx', JSON.stringify(payload))
        },
    },
    
    actions: { 
        setItemSelected({state, commit}, payload){
            const { type, selected } = payload

            if(type === 'IN'){
                commit("setItemSelectedForIn", selected)
                // 選項儲存到 Session
                sessionStorage.setItem('material_pending_item_selected_for_in', JSON.stringify(selected))
            }
            else if(type === 'OUT'){
                commit("setItemSelectedForOut", selected)
                // 選項儲存到 Session
                sessionStorage.setItem('material_pending_item_selected_for_out', JSON.stringify(selected))
            }

        },
        removeItemSelected({state, commit}){
            commit("setItemSelectedForIn", [])
            commit("setItemSelectedForOut", [])
            sessionStorage.removeItem('material_pending_item_selected_for_in')
            sessionStorage.removeItem('material_pending_item_selected_for_out')
        },
    },

}