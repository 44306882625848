import { loadView } from "@/router/loadView"
export default [

    //#region 【3】 (外部產品)
    {
        path: '/external-product',
        name: 'ExternalProduct',
        component: loadView("FoodProcessing/ExternalProduct/ExternalView"),
        meta: { roles: ['admin', 'PT']  }, // 需要為 Admin
        redirect: '/external-product/inspect/sweet',
        children: [
            { // 該產品資訊
                path: 'inspect/sweet',
                name: 'ExternalSweetDashboard',
                component: loadView("FoodProcessing/ExternalProduct/Inspect/dashboard/ExternalSweetDashboard"),
                meta: { roles: ['admin', 'PT'] } // 需要為 Admin
            },
            { // 該產品資訊
                path: 'inspect/color',
                name: 'ExternalColorDashboard',
                component: loadView("FoodProcessing/ExternalProduct/Inspect/dashboard/ExternalColorDashboard"),
                meta: { roles: ['admin', 'PT'] } // 需要為 Admin
            },
            { // 該產品資訊
                path: 'inspect/fullness',
                name: 'ExternalFullnessDashboard',
                component: loadView("FoodProcessing/ExternalProduct/Inspect/dashboard/ExternalFullnessDashboard"),
                meta: { roles: ['admin', 'PT'] } // 需要為 Admin
            },
            { // 該產品資訊
                path: 'inspect/ice',
                name: 'ExternalIceDashboard',
                component: loadView("FoodProcessing/ExternalProduct/Inspect/dashboard/ExternalIceDashboard"),
                meta: { roles: ['admin', 'PT'] } // 需要為 Admin
            },
            { // 該產品資訊
                path: 'inspect/taste',
                name: 'ExternalTasteDashboard',
                component: loadView("FoodProcessing/ExternalProduct/Inspect/dashboard/ExternalTasteDashboard"),
                meta: { roles: ['admin', 'PT'] } // 需要為 Admin
            },
        ]
    },
    { // 該產品資訊
        path: '/external-product/view/:id',
        name: 'ExternalProductView',
        component: loadView("FoodProcessing/ExternalProduct/Product/ExternalProductView"),
        meta: { roles: ['admin', 'PT'] }, // 需要為 Admin
        redirect: "/external-product/view/:id/read",
        children: [
            { // 該產品資訊
                path: 'read',
                name: 'ReadExternalProductView',
                component: loadView("FoodProcessing/ExternalProduct/Product/ReadExternalProductView"),
                meta: { roles: ['admin', 'PT'] } // 需要為 Admin
            },
            { // 該產品資訊
                path: 'inspect/sweet',
                name: 'ExternalSweetView',
                component: loadView("FoodProcessing/ExternalProduct/Inspect/view/ExternalSweetView"),
                meta: { roles: ['admin', 'PT'] } // 需要為 Admin
            },
            { // 該產品資訊
                path: 'inspect/color',
                name: 'ExternalColorView',
                component: loadView("FoodProcessing/ExternalProduct/Inspect/view/ExternalColorView"),
                meta: { roles: ['admin', 'PT'] } // 需要為 Admin
            },
            { // 該產品資訊
                path: 'inspect/fullness',
                name: 'ExternalFullnessView',
                component: loadView("FoodProcessing/ExternalProduct/Inspect/view/ExternalFullnessView"),
                meta: { roles: ['admin', 'PT'] } // 需要為 Admin
            },
            { // 該產品資訊
                path: 'inspect/ice',
                name: 'ExternalIceView',
                component: loadView("FoodProcessing/ExternalProduct/Inspect/view/ExternalIceView"),
                meta: { roles: ['admin', 'PT'] } // 需要為 Admin
            },
            { // 該產品資訊
                path: 'inspect/taste',
                name: 'ExternalTasteView',
                component: loadView("FoodProcessing/ExternalProduct/Inspect/view/ExternalTasteView"),
                meta: { roles: ['admin', 'PT'] } // 需要為 Admin
            },
        ]
    },
    //#endregion

    { // 創建產品
        path: '/external-product/create',
        name: 'CreateExternalProductView',
        component: loadView("FoodProcessing/ExternalProduct/Product/CreateExternalProductView"),
        meta: { roles: ['admin', 'PT'] } // 需要為 Admin
    },
    //#endregion

    { // 修改產品
        path: '/external-product/view/:id/update',
        name: 'UpdateExternalProductView',
        component: loadView("FoodProcessing/ExternalProduct/Product/UpdateExternalProductView"),
        meta: { roles: ['admin', 'PT'] } // 需要為 Admin
    },
    //#endregion

  

];