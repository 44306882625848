<template>
  <!-- Drawer Component: 
    ● 行動端: temporary = true
  -->
  <v-navigation-drawer app touchless v-model="drawerState" color="white" :temporary="is_mobile"
    @input="toggleNavigation" style="z-index: 99;">

    <!-- 用於把 Footer Menu給隔開 -->
    <div class="d-flex flex-column justify-space-between fill-height">

      <!-- Header + Router Link List -->
      <div class="sidebar-header">
        <!-- Header -->
        <v-list class="header">
          <v-list-item>
            <!-- avatar -->
            <v-list-item-avatar>
              <v-img :src="require('@/assets/images/layouts/sidebar/chiwawa/logo.jpg')"></v-img>
            </v-list-item-avatar>

            <!-- intro -->
            <v-list-item-content>
              <!-- 使用者名稱 -->
              <v-list-item-title class="font-weight-medium d-flex flex-wrap align-center" style="gap: 0.25rem;">
                <h6 class="font-weight-black text-subtitle-1 text-lg-h6">{{ name }}</h6>
              </v-list-item-title>

              <!-- 角色 -->
              <v-list-item-subtitle class="d-flex flex-wrap gap-2">
                <v-chip small class="white--text" v-for="role, i in roles" :key="role" :color="role === 'admin'
                  ? 'red darken-2'
                  : role === 'manager'
                    ? 'green lighten'
                    : 'blue'">
                  {{ role_names ? role_names[i] : role }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider width="80%" class="mx-auto my-2"></v-divider>

        <!-- Router Link List -->
        <MenuItem :routes="role_routes" />

      </div>


      <!-- Footer -->
      <div class="sidebar-header">
        <!-- 加條白線 -->
        <v-divider color="white"></v-divider>


        <!-- Footer(routes就在該組件中) -->
        <v-list nav shaped class="sidebar-footer">

          <!-- 登出 -->
          <v-list-item class="menu v-list-item" path @click="logout">
            <!-- 路由 Icon -->
            <v-list-item-icon>
              <v-icon class="menu-icon">mdi-logout</v-icon>
            </v-list-item-icon>

            <!-- 路由標題 -->
            <v-list-item-title class="menu-text">
              登出
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </div>

  </v-navigation-drawer>


</template>

<script>
import { mapState } from 'vuex'
import MenuItem from '@/components/SidebarMenu/MenuItem.vue';
export default {
  name: "Sidebar",
  components: {
    MenuItem,
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },

  },


  data: () => ({
    drawerState: null, // 開關
    // routes: [], // 動態路由(vue-router)

    routes: [ /* 沒有 requireXXX: 代表該頁面只有 Admin才可以瀏覽 ； allowAll: true 代表所有使用者都可以瀏覽 */
      { name: "Home", path: '/f', meta: { is_menu: true, title: '首頁', icon: 'home' } },


      // /* 1. 養殖紀錄 (除了 Jutian其餘都能瀏覽) */
      // { name: "FeedRecord", path: '/feed-record', meta: {is_menu: true, title: '養殖紀錄', roles: ['admin', 'manager', 'team_leader', 'editor'], icon: 'mdi-book-plus',},  },
      /* 1. 養殖紀錄 (除了 Jutian其餘都能瀏覽) */
      {
        meta: { is_menu: true, title: "養殖紀錄", roles: ['admin', 'manager', 'team_leader', 'editor', 'accountant', 'proxy', 'shipper'], icon: 'mdi-vector-polyline-edit' },
        children: [
          { name: "FeedRecordBeta", path: '/feed-record-mobile', meta: { is_menu: true, title: '養殖記錄(手機)', roles: ['admin', 'manager', 'team_leader', 'editor', 'proxy'], icon: 'mdi-lead-pencil', }, },
          // { name: "FeedRecord", path: '/feed-record', meta: { is_menu: true, title: '養殖紀錄', roles: ['admin', 'manager', 'team_leader', 'editor'], icon: 'mdi-book-plus', }, },
          { name: "DailyHistoryView", path: '/daily-history', meta: { is_menu: true, title: '歷史記事日誌', roles: ['admin', 'manager', 'team_leader', 'editor', 'proxy'], icon: 'mdi-clipboard-text-clock', }, },
          { name: "DestoryHistoryView", path: '/destroy-history', meta: { is_menu: true, title: '放流日誌', roles: ['admin', 'manager', 'team_leader', 'proxy'], icon: 'mdi-approximately-equal-box', }, },
          { name: "WaterHistoryView", path: '/water-history', meta: { is_menu: true, title: '水質日誌', roles: ['admin', 'manager', 'team_leader', 'accountant', 'proxy'], icon: 'mdi-water', }, },
          { name: "WaterRecordForSeed", path: '/water-record-for-seed', meta: { is_menu: true, title: '出苗前水質檢驗', roles: ['admin', 'manager', 'team_leader', 'editor', 'proxy'], icon: 'mdi-water', }, },
          { name: "MaterialHomeView", path: '/material/orders', meta: { is_menu: true, title: '叫料訂單', roles: ['admin', 'shipper'], icon: 'mdi-account-voice', }, },
          { name: "MaterialHistoryView", path: '/material-history', meta: { is_menu: true, title: '叫料日誌', roles: ['admin', 'manager', 'team_leader', 'proxy', 'editor'], icon: 'mdi-palette-swatch-variant', }, },
          // { name: "MaintenanceHistoryView", path: '/maintenance-history', meta: { is_menu: true, title: '維運日誌', roles: ['admin', 'manager', 'team_leader', 'proxy', 'editor'], icon: 'mdi-tools', }, },
          { name: "MaintenanceHistoryView", path: '/maintenance-history', meta: { is_menu: true, title: '維運日誌', roles: ['tester'], icon: 'mdi-tools', }, },
        ]
      },


      // 2. 養殖管理 (admin + manager)12
      {
        meta: { is_menu: true, title: "養殖管理", roles: ['admin', 'manager', 'team_leader', 'accountant'], icon: 'mdi-feature-search' },
        children: [
          { name: 'FeedDiary', path: '/feed-diary', meta: { is_menu: true, title: '養殖日誌', roles: ['admin', 'manager', 'team_leader', 'accountant'], icon: 'history_edu' } },
          { name: 'DailyReport', path: '/daily-report', meta: { is_menu: true, title: '日報表', roles: ['admin', 'manager', 'team_leader'], icon: 'mdi-notebook' } },
          { name: 'FeedManageView', path: '/feed-management', meta: { is_menu: true, title: '餵食量管理', roles: ['admin', 'manager', 'team_leader'], icon: 'mdi-bandage' } },
          { name: 'EmptyManageView', path: '/empty-management', meta: { is_menu: true, title: '空池管理', roles: ['admin', 'manager', 'team_leader', 'accountant'], icon: 'mdi-emby' } },
          { name: 'TransferPoolManageView', path: '/transfer-pool-management', meta: { is_menu: true, title: '移池管理', roles: ['admin', 'manager', 'team_leader', 'accountant'], icon: 'mdi-transfer' } },
          { name: 'MaterialManageView', path: '/material-management', meta: { is_menu: true, title: '叫料管理', roles: ['admin', 'manager', 'team_leader', 'accountant'], icon: 'mdi-truck-delivery' } },
        ]
      },

      // 3. 營運管理 (admin + manager)
      {
        meta: { is_menu: true, title: "營運管理", roles: ['admin', 'manager', 'team_leader', 'accountant', 'marketing'], icon: 'mdi-heat-pump-outline' },
        children: [
          { name: 'ShrimpSeed', path: '/shrimp-seed', meta: { is_menu: true, title: '苗源分析', roles: ['admin', 'manager', 'team_leader', 'accountant'], icon: 'mdi-seed' } },
          { name: 'SeedManagementView', path: '/management/seed/view', meta: { is_menu: true, title: '苗源管理', roles: ['admin', 'manager', 'team_leader', 'accountant'], icon: 'mdi-seed' } },
          { name: 'ScheduleAnalysis', path: '/shrimp-schedule/view', meta: { is_menu: true, title: '排程分析', roles: ['admin', 'manager', 'team_leader'], icon: 'mdi-school' } },
          { name: 'ScheduleAnalysisHistory', path: '/shrimp-schedule/history/view', meta: { is_menu: true, title: '排程分析(歷史)', roles: ['admin', 'manager', 'team_leader'], icon: 'mdi-clipboard-text-clock' } },
          { name: 'ScheduleHarvestPredict', path: '/harvest-analysis', meta: { is_menu: true, title: '收成預測', roles: ['admin', 'manager', 'team_leader', 'marketing', 'accountant'], icon: 'mdi-certificate' } },
          { name: 'ScheduleGrowAnalysis', path: '/grow-analysis', meta: { is_menu: true, title: '成長分析', roles: ['admin', 'manager', 'team_leader'], icon: 'mdi-chart-line-stacked' } },
          { name: 'SellManagement', path: '/sell-management', meta: { is_menu: true, title: '出貨管理', roles: ['admin', 'manager', 'team_leader', 'accountant', 'marketing'], icon: 'mdi-cart' } },
          { name: 'ScheduleAlertInput', path: '/schedule-alert-input', meta: { is_menu: true, title: '填寫檢查表', roles: ['admin', 'manager'], icon: 'mdi-alert-box' } },
          { name: 'DashboardCenter', path: '/dashboard-center', meta: { is_menu: true, title: '戰情中心', roles: ['admin', 'manager'], icon: 'mdi-monitor-dashboard' } },
        ]
      },


      // 4. 報表管理 (admin)
      {
        meta: { is_menu: true, title: "報表管理", roles: ['admin', 'accountant', 'marketing'], icon: 'mdi-folder-multiple' },
        children: [
          { name: 'HarvestMonthView', path: '/ReportManagement/havest-month-report', meta: { is_menu: true, title: '收成月報表', roles: ['admin', 'accountant'], icon: 'mdi-file-excel' } },
          { name: 'ScheduleHistoryYear', path: '/ReportManagement/schedule-history/2023', meta: { is_menu: true, title: '2023年報-排程收成', roles: ['admin'], icon: 'mdi-file-excel' } },
          { name: 'HarvestHistoryYearView', path: '/ReportManagement/harvest-history/2023', meta: { is_menu: true, title: '2023年報-收成報表', roles: ['admin', 'accountant'], icon: 'mdi-file-excel' } },
          // {name: 'ScheduleHistoryYearReport', path: '/ReportManagement/schedule-history-report', meta: {is_menu: true, title: '歷史排程收成年報表', roles: ['admin'], icon: 'mdi-file-excel'}},
          { name: 'HistoryYearReport', path: '/ReportManagement/history-report', meta: { is_menu: true, title: '歷史年報表', roles: ['admin', 'accountant', 'marketing'], icon: 'mdi-file-excel' } },
          { name: 'ScheduleBonusAnalysis', path: '/bonus-analysis', meta: { is_menu: true, title: '獎金系統', roles: ['admin', 'accountant'], icon: 'mdi-gift' } },
          { name: 'TotalAnnualKPIManagement', path: '/total-annual-kpi', meta: { is_menu: true, title: '總目標管理', roles: ['admin'], icon: 'mdi-gift' } },
        ]
      },


      // 4. 獎金管理 (admin、manager)
      {
        meta: { is_menu: true, title: "獎金管理", roles: ['admin', 'manager', 'team_leader', 'accountant'], icon: 'mdi-wallet-giftcard' },
        children: [
          { name: 'ScheduleBonusMonthReport', path: '/bonus-month-report', meta: { is_menu: true, title: '獎金月報表', roles: ['admin', 'manager', 'team_leader', 'accountant'], icon: 'mdi-gift' } },
        ]
      },

      // 4. 維運管理 (admin、manager)
      {
        meta: { is_menu: true, title: "維運管理", roles: ['tester'], icon: 'mdi-tools' },
        children: [
          { name: 'MaintenanceOrderView', path: '/maintenance-order-view', meta: { is_menu: true, title: '維運訂單', roles: ['tester'], icon: 'mdi-wrench-clock' } },
          { name: 'MaintenanceEquipmentView', path: '/maintenance-equipment-view', meta: { is_menu: true, title: '設備清冊', roles: ['tester'], icon: 'mdi-washing-machine' } },
          { name: 'MaintenanceAssetView', path: '/maintenance-asset-view', meta: { is_menu: true, title: '固資財產', roles: ['tester'], icon: 'mdi-vhs' } },
          { name: 'MaintenanceEquipmentInventoryView', path: '/maintenance/equipment/inventory/view', meta: { is_menu: true, title: '設備品項', roles: ['tester'], icon: 'mdi-invoice-list' } },
          { name: 'MaintenanceEquipmentPositionInventoryAllocateView', path: '/maintenance/equipment/position/inventory-allocate/view', meta: { is_menu: true, title: '案場設備配置', roles: ['tester'], icon: 'mdi-devices' } },
          { name: 'MaintenanceDeviceView', path: '/maintenance-device-view', meta: { is_menu: true, title: '案場設備管理', roles: ['tester'], icon: 'mdi-devices' } },
        ]
      },
      // {
      //   meta: { is_menu: true, title: "維運管理", roles: ['admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'], icon: 'mdi-tools' },
      //   children: [
      //     { name: 'MaintenanceOrderView', path: '/maintenance-order-view', meta: { is_menu: true, title: '維運訂單', roles: ['admin', 'manager', 'maintenance_assigner', 'maintenance_receiver', 'maintenance_technician'], icon: 'mdi-wrench-clock' } },
      //     { name: 'MaintenanceEquipmentView', path: '/maintenance-equipment-view', meta: { is_menu: true, title: '設備清冊', roles: ['admin', 'manager', 'maintenance_assigner'], icon: 'mdi-washing-machine' } },
      //     { name: 'MaintenanceAssetView', path: '/maintenance-asset-view', meta: { is_menu: true, title: '固資財產', roles: ['admin', 'manager', 'maintenance_assigner'], icon: 'mdi-vhs' } },
      //     { name: 'MaintenanceEquipmentInventoryView', path: '/maintenance/equipment/inventory/view', meta: { is_menu: true, title: '設備品項', roles: ['admin', 'manager', 'maintenance_assigner'], icon: 'mdi-invoice-list' } },
      //     { name: 'MaintenanceEquipmentPositionInventoryAllocateView', path: '/maintenance/equipment/position/inventory-allocate/view', meta: { is_menu: true, title: '案場設備配置', roles: ['admin', 'manager', 'maintenance_assigner', 'team_leader'], icon: 'mdi-devices' } },
      //     { name: 'MaintenanceDeviceView', path: '/maintenance-device-view', meta: { is_menu: true, title: '案場設備管理', roles: ['admin', 'manager', 'maintenance_assigner', 'team_leader'], icon: 'mdi-devices' } },
      //   ]
      // },

      // 5. 竹田食品加工 (admin全看)、(jutian只有RCW360)
      {
        meta: { is_menu: true, title: "竹田食品加工", roles: ['admin', 'PT', 'jutian', 'marketing'], icon: 'mdi-cogs' },
        children: [
          { // 食品檢驗
            name: "FoodInspectView", meta: { is_menu: true, title: "食品資料", roles: ['admin', 'PT'] },
            children: [
              { name: 'FoodInspectView_5101', path: '/foods/5101/inspect', meta: { is_menu: true, title: '帶頭蝦', roles: ['admin', 'PT'], icon: 'mdi-numeric-1-box' } },
              { name: 'FoodInspectView_5102', path: '/foods/5102/inspect', meta: { is_menu: true, title: '蝦仁', roles: ['admin', 'PT'], icon: 'mdi-numeric-2-box' } },
              { name: 'FoodInspectView_5103', path: '/foods/5103/inspect', meta: { is_menu: true, title: '拉長蝦', roles: ['admin', 'PT'], icon: 'mdi-numeric-3-box' } },
              { name: 'FoodInspectView_5104', path: '/foods/5104/inspect', meta: { is_menu: true, title: '開背蝦', roles: ['admin', 'PT'], icon: 'mdi-numeric-4-box' } },

            ],
          },
          { // 上傳資料
            meta: { is_menu: true, title: "上傳資料", roles: ['admin', 'PT'] },
            children: [
              { name: 'UploadTasteImage', path: '/food-upload/taste/image', meta: { is_menu: true, title: '品評圖片', roles: ['admin', 'PT'], icon: 'mdi-numeric-1-box' } },
              { name: 'UploadColorImage', path: '/food-upload/color/image', meta: { is_menu: true, title: '蝦色圖片', roles: ['admin', 'PT'], icon: 'mdi-numeric-2-box' } },
              { name: 'UploadFoodExcel', path: '/food-upload/excel', meta: { is_menu: true, title: '上傳食品資料', roles: ['admin', 'PT'], icon: 'mdi-numeric-3-box' } },
            ],
          },
          { // 食品管理
            meta: { is_menu: true, title: "食品管理", roles: ['admin', 'PT'] },
            children: [
              { name: 'FoodManagementForWhole', path: '/food-management/for-whole', meta: { is_menu: true, title: '帶頭蝦', roles: ['admin', 'PT'], icon: 'mdi-numeric-1-box' } },
              { name: 'FoodManagementForProcess_5102', path: '/food-management/for-process/5102', meta: { is_menu: true, title: '蝦仁', roles: ['admin', 'PT'], icon: 'mdi-numeric-2-box' } },
              { name: 'FoodManagementForProcess_5103', path: '/food-management/for-process/5103', meta: { is_menu: true, title: '拉長蝦', roles: ['admin', 'PT'], icon: 'mdi-numeric-3-box' } },
              { name: 'FoodManagementForProcess_5104', path: '/food-management/for-process/5104', meta: { is_menu: true, title: '開背蝦', roles: ['admin', 'PT'], icon: 'mdi-numeric-4-box' } },
            ],
          },
          { name: 'FoodTaste', path: '/food-taste', meta: { is_menu: true, title: '感官品評', roles: ['admin', 'PT'], icon: 'mdi-noodles' } },
          { name: 'ExternalProduct', path: '/external-product', meta: { is_menu: true, title: '外部食品', roles: ['admin', 'PT'], icon: 'mdi-food-turkey' } },
          { name: 'RCW360_ThermometerList', path: '/rcw360-temp', meta: { is_menu: true, title: '竹田溫度監控系統', roles: ['admin', 'PT', 'jutian'], icon: 'mdi-thermometer-lines' } },
          { name: 'Product', path: '/product', meta: { is_menu: true, title: '生產管理', roles: ['admin', 'PT', 'marketing'], icon: 'dynamic_form' } },
        ]
      },

      // 6. 病毒檢驗 (admin)
      { name: 'ShrimpVirus', path: '/shrimp-virus', meta: { is_menu: true, title: '病毒檢測', roles: ['admin'], icon: 'coronavirus', } },

      // 9.蝦苗管理 (admin)
      {
        name: 'SporutList', meta: { is_menu: true, title: "蝦苗管理", roles: ['admin', 'manager'], icon: 'mdi-access-point-check' },
        children: [
          { name: 'SproutView', path: '/sprout-list', meta: { is_menu: true, title: '芽管理', roles: ['admin', 'manager'], icon: 'mdi-arrow-decision' } },
          { name: 'SeedManagement', path: '/seed-management', meta: { is_menu: true, title: '蝦苗總覽', roles: ['admin', 'manager'], icon: 'mdi-alarm-light' } },
        ]
      },

      // 7. JNC檢驗 (admin)
      { name: 'JNCDemo', path: '/jnc-demo', meta: { is_menu: true, title: 'JNC檢測', roles: ['admin'], icon: 'mdi-remote', } },

      // 8. M10太陽能板
      { name: 'M10_PanelView', path: '/m10-panel', meta: { is_menu: true, title: 'M10太陽能板測試', roles: ['admin'], icon: 'solar_power' } },


      // 10.場域地圖 (admin + manager)
      { name: 'Station', path: '/station', meta: { is_menu: true, title: '場域地圖', roles: ['admin', 'manager', 'team_leader', 'accountant', 'marketing'], icon: 'mdi-map' } },

      // 11.水文資訊 (admin)
      {
        name: 'HydrologyManagement', meta: { is_menu: true, title: "水文資訊", roles: ['admin'], icon: 'mdi-water-pump' },
        children: [
          { name: 'HydrologyExternalView', path: '/hydrology/external/view', meta: { is_menu: true, title: '外部資訊', roles: ['admin'], icon: 'mdi-water' } },
          { name: 'HydrologyInternalView', path: '/hydrology/internal/view', meta: { is_menu: true, title: '台大資訊', roles: ['admin'], icon: 'mdi-water' } },
          { name: 'HydrologyExternalTideAreaView', path: '/hydrology/external/tide/area', meta: { is_menu: true, title: '區域潮汐資訊', roles: ['admin'], icon: 'mdi-waves-arrow-up' } },
          { name: 'HydrologyExternalTideStationView', path: '/hydrology/external/tide/station', meta: { is_menu: true, title: '潮位站資訊', roles: ['admin'], icon: 'mdi-waves-arrow-up' } },
          { name: 'HydrologyMixedView', path: '/hydrology/mixed/view', meta: { is_menu: true, title: '水文混合資訊', roles: ['admin'], icon: 'mdi-waves-arrow-up' } },
          { name: 'WaterQuality', path: '/hydrology/water-quality', meta: { is_menu: true, title: '水質檢驗', roles: ['admin'], icon: 'water' } },
        ]
      },

      // 11.分組管理 (admin)
      {
        name: 'TeamManagement', meta: { is_menu: true, title: "分組管理", roles: ['admin', 'manager', 'team_leader'], icon: 'mdi-briefcase-account' },
        children: [
          { name: 'TeamDashboard', path: '/team/dashboard', meta: { is_menu: true, title: '控制台', roles: ['admin', 'manager', 'team_leader'], icon: 'mdi-console' } },
          // {name: 'TeamGoalAnalysis', path: '/team/goal-analysis' , meta: {is_menu: true, title: '目標統計表', roles: ['admin', 'manager', 'team_leader'], icon: 'mdi-table-eye'}},
        ]
      },
      // 12.系統管理 (admin)
      {
        name: 'SystemManagement', meta: { is_menu: true, title: "系統管理", roles: ['admin', 'manager', 'team_leader'], icon: 'mdi-cog-box' },
        children: [
          { name: 'UserManagement', path: '/system/user', meta: { is_menu: true, title: '使用者管理', roles: ['admin', 'manager', 'team_leader'], icon: 'mdi-account-box' } },
          { name: 'GroupManagement', path: '/system/group', meta: { is_menu: true, title: '角色管理', roles: ['admin'], icon: 'mdi-briefcase-account' } },
          { name: 'TeamManagement', path: '/system/team', meta: { is_menu: true, title: '小組管理', roles: ['admin', 'manager', 'team_leader'], icon: 'mdi-account-group' } },
        ]
      },
      // 13.區域管理 (admin)
      {
        name: 'LocationManagement', meta: { is_menu: true, title: "區域管理", roles: ['admin'], icon: 'mdi-map-marker' },
        children: [
          { name: 'CompanyManagement', path: '/location/company', meta: { is_menu: true, title: '公司別管理', roles: ['admin'], icon: 'mdi-city' } },
          { name: 'CircuitManagement', path: '/location/circuit', meta: { is_menu: true, title: '迴路管理', roles: ['admin'], icon: 'mdi-atom-variant' } },
          { name: 'AreaManagement', path: '/location/area', meta: { is_menu: true, title: '地區別管理', roles: ['admin'], icon: 'mdi-domain' } },
          { name: 'PositionManagement', path: '/location/position', meta: { is_menu: true, title: '案場管理', roles: ['admin'], icon: 'mdi-factory' } },
        ]
      },
      // 13.養殖實績 (admin)
      {
        name: 'HistoryTransferManagement', meta: { is_menu: true, title: "養殖實績", roles: ['data_controller'], icon: 'mdi-cog-transfer' },
        children: [
          { name: 'TransferScheduleView', path: '/transfer-schedule', meta: { is_menu: true, title: '調控操作頁面', roles: ['data_controller'], icon: 'mdi-briefcase-arrow-left-right' } },
          { name: 'TransferDashboardView', path: '/transfer/dashboard', meta: { is_menu: true, title: '調控儀錶板', roles: ['data_controller'], icon: 'mdi-monitor-dashboard' } },
          { name: 'TransferSellView', path: '/transfer-sell', meta: { is_menu: true, title: '調控轉移紀錄', roles: ['data_controller'], icon: 'mdi-transfer' } },
        ]
      },
      // 13.附屬綠能 (admin)
      {
        name: 'HistoryTransferForEnergyManagement', meta: { is_menu: true, title: "附屬綠能", roles: ['data_controller'], icon: 'mdi-home-lightning-bolt' },
        children: [
          { name: 'TransferPositionConfigurationView', path: '/transfer/for-energy/position/configuration', meta: { is_menu: true, title: '案場目標值設定', roles: ['data_controller'], icon: 'mdi-cog' } },
          { name: 'TransferForEnergyPlanningView', path: '/transfer/for-energy/planning', meta: { is_menu: true, title: '計畫安排', roles: ['data_controller'], icon: 'mdi-invoice-text-clock' } },
          { name: 'TransferForEnergyDashboardView', path: '/transfer/for-energy/dashboard', meta: { is_menu: true, title: '調控儀錶板', roles: ['data_controller'], icon: 'mdi-monitor-dashboard' } },
          { name: 'TransferSellForEnergySellListView', path: '/transfer/for-energy/sell-list', meta: { is_menu: true, title: '轉移出貨列表', roles: ['data_controller'], icon: 'mdi-invoice-list' } },
          { name: 'TransferForEnergySellView', path: '/transfer/for-energy/sell', meta: { is_menu: true, title: '出貨轉移紀錄', roles: ['data_controller'], icon: 'mdi-transfer' } },
        ]
      },

      // { name: "InputRecord", path: '/input-record/view/S104/F1', meta: {is_menu: true, title: '紀錄優化', roles: ['admin'], icon: 'home'} },
      // { title: '運蝦車及製程溫度監控', icon: 'local_shipping', path: '/delivery' },
      // { title: '場池區域管理', icon: 'mdi-cog-box', path: '/test' },

      // { title: '蝦苗病毒檢測', icon: 'coronavirus', path: '/virus', exact: false },
      // { title: '檔案下載', icon: 'folder', path: '/file', exact: false },
      // { title: '蝦苗重量評估', icon: 'analytics', path: '/weight' },
      // { title: '預期成長曲線', icon: 'query_stats', path: '/grow-analysis', exact: false },
      // { title: '天氣資訊', icon: 'sunny', path: '/weather', exact: false },
      // { title: '蝦苗餵食量記錄', icon: 'dining', path: '/feed' },
      // { title: '食品資料(測試)', icon: 'mdi-hamburger', path: '/food-stuff-demo' },
      // { title: '功能測試區域', icon: 'star_rate', path: '/test' },

    ],


    

  }),

  mounted() {
    //   this.routes = this.$router.options.routes // 讀取 Vue-Router所定義的路由列表
  },

  computed: {
    ...mapState('auth', { // 捕捉使用者資訊(使用者名稱、權限角色)
      name: state => state.user_info.name,
      roles: state => state.user_info.roles,
      role_names: state => state.user_info.role_names,
    }),

    role_routes() {
      const { roles, routes, editor_routes } = this
      return routes
      // return roles.length === 1 && roles.includes('editor')
      //   ? editor_routes
      //   : routes
    },


  },

  watch: {
    drawer: { // 監聽 props過來的 drawer(與 Navbar共用)
      handler(newVal) {
        this.drawerState = newVal // 該 Component下繼承App.vue的 drawer，會互相影響
      }
    }
  },

  methods: {
    // 將該 Component針對 drawer影響的變化傳回 App.vue
    toggleNavigation(isDraw) {
      this.$emit("toggleNavigation", isDraw)
    },

    async logout() { // 登出
      const swal = await this.$swal.fire({
        title: '登出提醒',
        text: '確定要登出了嗎?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
      })

      if (swal.isConfirmed) {
        localStorage.clear()
        this.$store.commit("auth/delToken")
        this.$store.commit("auth/delUser")
        this.$router.push({
          name: 'LoginView'
        })

        this.$swal.fire("登出成功!", "", "success")
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
}


// Before Hover
.menu {
  transition: 0.3s ease-in-out;

  .menu-icon {
    color: grey;
  }

  .menu-text {
    color: black;
  }
}

// Active Menu + After Hover
.active-menu {
  .menu-icon {
    background: #0575E6;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #021B79, #0575E6);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #021B79, #0575E6);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  .menu-text {
    background: #0575E6;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #021B79, #0575E6);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #021B79, #0575E6);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-weight: bolder;
  }

}

@media screen and (max-width: 650px) {
  .sidebar-footer {
    margin-bottom: 4rem;
  }
}
</style>>