import { loadView } from "@/router/loadView"

export default [

    { // 退料資訊
        path: '/f/alert-material-out',
        name: 'AlertMaterialOutListView',
        component: loadView("Home/AlertMaterialOut/AlertMaterialOutListView"),
        meta: {
            roles: ['admin', 'keeper']
        }
    },
    { // 單一退料資訊
        path: '/f/alert-material-out/detail/:id',
        name: 'AlertMaterialOutDetailView',
        component: loadView("Home/AlertMaterialOut/AlertMaterialOutDetailView"),
        meta: {
            roles: ['admin', 'keeper']
        }
    },

    
];