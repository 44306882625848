import service from '@/api/service';

class BaseService {
  constructor(resource) { // 透過建構子取得 resource
    this.resource = resource;
  }

  getAll(params) {
    return service.get(`/${this.resource}/`, {params});
  }

  getById(id, params) {
    return service.get(`/${this.resource}/${id}/`, {params});
  }

  create(data, params) {
    return service.post(`/${this.resource}/`, data, {params});
  }

  update(id, data, params) {
    return service.put(`/${this.resource}/${id}/`, data, {params});
  }

  patch(id, data, params) {
    return service.patch(`/${this.resource}/${id}/`, data, {params});
  }

  delete(id) {
    return service.delete(`/${this.resource}/${id}/`);
  }

  restore(id){ // 復原軟刪除
    return service.post(`/${this.resource}/${id}/restore/`);
  }

  hard_delete(id){ // 永久刪除軟刪除數據
    return service.delete(`/${this.resource}/${id}/hard-delete/`);
  }

  // 批次功能
  batch_delete(data){ // 批次刪除
    return service.post(`/${this.resource}/batch-delete/`, data);
  }
  batch_restore(data){ // 批次恢復
    return service.post(`/${this.resource}/batch-restore/`, data);
  }
  batch_hard_delete(data){ // 批次永久刪除
    return service.post(`/${this.resource}/batch-hard-delete/`, data);
  }

  bulk_create_or_update(data, headers){ // 批次新增或更新
    return service.post(`/${this.resource}/bulk-create-or-update/`, data, headers);
  }

}

export default BaseService;